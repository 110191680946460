import React,{useRef,useEffect,useState} from 'react'
import classNames from 'classnames'
import FooterCom from '@/components/Footer'
import config from '../utils/config'
import Logo from '@/components/Logo'
import {Link} from "react-router-dom";

const HeaderCom=(props)=>{
    return <div className="home-header theme3">
        <div className="home-header-center flex justify-between items-center">
            <div className="left-box flex items-center">
                <Logo />
                <div className="fz20 lh28 ml10 text-white ffps">{config.appName}</div>
            </div>
            <div className="top-link fz20 lh28 ffps">
                <a href={`mailto:${config.contactEmail}`} className="text-white mr40 ffps">Contact Us</a>
                <Link to="/privacy" className="text-white mr40 ffps">Privacy Policy</Link>
                <Link to="/termsOfService" className="text-white ffps">Terms of Service</Link>
                {
                    config.appName === 'GreatNovel' ?  <Link to="/membershipTerms" className="text-white hover-primary opa8">Membership Terms</Link> : null
                }
            </div>
        </div>
    </div>
}

const Index=()=> {
    const bannerRef=useRef();
    const [downloadList,setDownloadList]=useState([]);

    let banner=require('@/assets/images/theme4/banner.png').default

    useEffect(()=>{
        let arr=[];
        let apple= {imgSrc:require('@/assets/images/appStore.png').default,url:config.appStoreUrl};
        let android=  {imgSrc:require('@/assets/images/googlePlay.png').default,url:config.googlePlayUrl};
        if(config.googlePlayUrl){
            arr.push(android)
        }
        if(config.appStoreUrl){
            arr.push(apple)
        }
        setDownloadList(arr)
    },[])
    return (
        <div className="min-winHeight relative home home-style4">
            <HeaderCom />

            <div className="img" style={{backgroundImage:`url(${banner})`,backgroundSize:'cover',backgroundPosition:'center center'}}></div>

            <div className="main">
                <div className="people">
                    <img src={require('@/assets/images/theme4/people.png').default} alt=""/>
                </div>
                <div className="right-main">
                    <div className="text-white fz140 appName lh196 title ffps">{config.appName}</div>
                    <div className="desc fz50 lh65 text-white ffes">
                    {
                        config.desc ? config.desc : ` Amazing Novels at Your Fingertips`
                    }

                    </div>
                    <div className="download">
                        {
                            downloadList.map((item,index)=>{
                                return <div onClick={()=>window.open(item.url)} className="download-item" key={index}>
                                    <img src={item.imgSrc} alt="" />
                                </div>
                            })
                        }
                    </div>
                </div>
                {/*<div className="right-box">*/}
                {/*    <img src={require('@/assets/images/phone.png').default} alt=""/>*/}
                {/*</div>*/}
            </div>
            <div className="footer-homebox">
                <div className="top-link fz20 lh28 fw400">
                    <a href={`mailto:${config.contactEmail}`} className="text-white hover-primary mr77 opa8">Contact Us</a>
                    <Link to="/privacy" className="text-white hover-primary mr77 opa8">Privacy Policy</Link>
                    <Link to="/termsOfService" className="text-white hover-primary opa8">Terms of Service</Link>
                </div>
                <div className="copyright">
                    Copyright © {new Date().getFullYear()} {config.appName} All Rights Reserved.
                </div>
            </div>
        </div>

    )
}

export default Index
