import React,{useState} from 'react'
import { Modal,Form, Input,message } from 'antd';
import config from "../../utils/config";
const Page = (props) => {
    let logoName=config.logoName || 'logo'

    const [form] = Form.useForm();
    const [isModalVisible,setModalVisible]=useState(false);
    const handleOk=()=>{
        form.validateFields().then(value=>{
            setModalVisible(false);
            message.success('Submitted successfully,we will process your request later ', 10)
        }).catch(err=>{
            console.log(err,'err')
        })

    }
    const handleCancel=()=>{
        setModalVisible(false)
    }

    return (
        <div className="page pad-lg pt40 pb60">
            <Modal title={
                [<div className="modal-title">Submit a Request<span>Modify Delete Data</span></div>]
            } cancelText="Cancel" okText="Submit" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form  colon={false} name="normal_login" className="login-form" form={form} >
                     <Form.Item label="Contact Information" name="contact" rules={[{ required: true, message: 'Please enter your contact information' }]}>
                       <Input placeholder="Please enter your contact information" />
                     </Form.Item>
                     <Form.Item label={<p>Requirement <br /> Description</p>} rules={[{ required: true, message: 'Please enter your request' }]}  name="demand">
                       <Input.TextArea  placeholder="Please enter your request (e.g delete my data)" />
                     </Form.Item>
                 </Form>
            </Modal>
            <div className="wrapper">
                <div className="logo">
                    <img src={require(`@/assets/images/${logoName}.png`).default} alt="" />
                </div>
                <div className="title fz40">Privacy Policy</div>
            </div>
            <div className="f-line"></div>
            {
           <div className="wrap fz24 lh40 mt40">
                <p>This Privacy Policy describes the data that we process to support Webfm apps, websites, and related services offered by Webfm ("Webfm"). You can find additional tools and data in the Webfm settings. It explains our practices concerning the personal data we collect from you, or that you provide to us, in connection with Webfm. If you do not agree with this policy, you should not use Webfm. If you have any questions about how we use your personal data, please contact webfm.cs@hotmail.com.</p>
                <p className="bold">1. Data Collection</p>
                <p>The type of data that we collect and process is dependent on how you use Webfm. For instance, the data we collect from the reader on Webfm is not the same as that from the author who publishes the novel on Webfm. Even if you don't have an account, if you use Webfm, we'll be able to collect some data about you.</p>
                <p>The data we collect and process is as follows:</p>
                <p className="fw400">I.Your Operation and Data you provide</p>
                <p>On Webfm, you can read or publish novels, make comments, and purchase coins for charged chapters and so on. We will collect the operation that you may perform on Webfm and the data you provide, such as:</p>
               <p>• the content you create, for example novel or comments;</p>
               <p>• the metadata about the content itself, for example upload address or creation documents date, in accordance with the relevant legislation;</p>

               <p>• the type of content that you read or comment;</p>
               <p>• the application and function you use, and your operation during this;</p>
               <p>• the purchase or other transactions you do including the credit card data;</p>
               <p>• the time, frequency, and duration of your operation.</p>

               <p className="fw400">Data under special protection</p>
               <p>You can choose to provide data about your age, gender, address, and email address. These and some other types of data may be specially protected under the laws of your jurisdiction.</p>

               <p className="fw400">II.Application, Browser and Device Data</p>
               <p>We will collect and receive data about the different devices you use and how you use these devices, and the data that comes from these devices.</p>
               <p>The device data that we collect and receive includes:</p>
               <p>• the device and software that you currently use, and other device characteristics;</p>
               <p>• the data that your device links to the Internet, including your IP address;</p>
               <p>• the data that is collected by Cookie (specified in Section 2) and other similar techniques.</p>
               <p className="fw400">III.Data that comes from Business Partner, Supplier and Third Party</p>
               <p>We will collect various data and operations that you are on or out of Webfm from business partner, supplier and third party. For example, we will collect your device data, your using application and the advertisement you view. Furthermore, a business partner will share us some other data, such as your email, third-party account data.</p>
                <p className="bold">2. Cookie</p>
               <p>Cookies are small pieces of text used to store data on web browsers. Cookies are used to store and receive identifiers and other data on computers, phones and other devices. Other technologies, including data that we store on your web browser or device, identifiers associated with your device and other software, are used for similar purposes. In this policy, we refer to all of these technologies as "cookies".</p>
                <p>We use cookies if you have a Webfm account, use Webfm, including our website and apps, or visit other websites and apps that use Webfm. Cookies enable Webfm to offer Webfm to you and to understand the data that we receive about you, including data about your use of other websites and apps, whether you are registered or logged in or not.</p>
               <p className="fw400">We use cookies to:</p>
               <p>• Verify log-in data.</p>
               <p>• Track traffic flow and patterns of travel in connection with the Services.</p>
               <p>• Understand the total number of visitors to the Services on an ongoing basis and the types of devices.</p>
               <p>• Monitor the performance of the Services and continually improve it.</p>
               <p>• Customize and enhance your online experience.</p>
               <p>• Provide customer service.</p>
               <p>You have the right to choose whether to accept cookies or not. However, please note that if you choose to refuse cookies. Certain parts of Webfm may not work properly if you have disabled browser cookie use. Please be aware that these controls are distinct from the controls that Webfm offers you.</p>
                <p>Most devices (in the case of mobile applications) and browsers (in the case of web apps and pages) are initially set up to accept cookies and allow you to change your cookie settings. These settings will typically be found in your browser's “options” or “preferences” menu. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
                <p className="bold">3. The Ways and Purpose of Data Processing</p>
               <p>The types of data we process and the purposes and ways we process it are as follows:
                   The purpose and ways that we process your data for and by	The type of data we use (specified in Section 1).</p>
               <p>The actual data we use will depend on your actual situation, but may include the following data:</p>
               <p>Providing Webfm involves collecting, storing, sharing, analyzing, reviewing, and collating data when necessary, and in some cases using not only automated processing techniques but also a manual (manual) review process to accomplish the following purposes:</p>
                <p>•Creating and maintaining your account and personal homepage;</p>
               <p>• Promoting content presenting;</p>
               <p>• Providing the comments function;</p>
               <p>• Offering the commercial novels; and</p>
               <p>• Carrying out an analysis.</p>
               <p className={'fw400'}>We also use the available data to develop, study, and test Webfm improvement. The data we have is used for the following purposes:</p>
               <p>• Knowing if Webfm is working properly;</p>
               <p>• Solving issues and correcting exceptions;</p>
               <p>• Testing New Features;</p>
               <p>• Acquiring feedback for Webfm or functions.	Your Operation and Data you provide</p>
               <p>• the content you create, for example novel or comments;</p>
               <p>• the metadata about the content itself, for example upload address or creation documents date, in accordance with the relevant legislation;</p>
               <p>• the type of content that you read or comment;</p>
               <p>• the application and function you use, and your operation during this;</p>
               <p>• the purchase or other transactions you do including the credit card data;</p>
               <p>• the time, frequency, and duration of your operation.</p>
               <p className="fw400">Application, Browser and Device Data</p>
               <p>• the device and software that you currently use, and other device characteristics;</p>
               <p>• the data that your device links to the Internet, including your IP address;</p>
               <p>• the data that is collected by Cookie (specified on Section 2) and other similar techniques.</p>
               <p className="fw400">Data that comes from Business Partner, Supplier and Third Party</p>
               <p>(You can control Webfm's action to use the business partner data to make a personalizing advertisement)</p>
               <p>We will process your relative data we control and use an automated processing technique, in some cases take a manual (manual)review process to accomplish the following purposes:</p>
                <p>• Verifying accounts and operations;</p>
               <p>• Investigating suspicious activity;</p>
               <p>• Verifying accounts and operations;</p>
               <p>•Detecting, preventing and combating harmful or illegal activities;</p>
               <p>•Monitoring and preventing spam, as well as other security issues and bad experiences.	Your Operation and Data you provide</p>
               <p>• the content you create, for example novel or comments;</p>
               <p>• the metadata about the content itself, for example upload address or creation documents date, in accordance with the relevant legislation;</p>
               <p>• the type of content that you read or comment;</p>
               <p>• the application and function you use, and your operation during this;</p>
               <p>• the purchase or other transactions you do including the credit card data;</p>
               <p>• the time, frequency, and duration of your operation.</p>
               <p className="fw400">Application, Browser and Device Data</p>
               <p>• the device and software that you currently use, and other device characteristics;</p>
               <p>• the data that your device links to the Internet, including your IP address;</p>
               <p>• the data that is collected by Cookie (specified on Section 2) and other similar techniques.</p>
               <p className="fw400">Data that comes from Business Partner, Supplier and Third Party</p>
               <p>Processing your data that is specifically protected by the law, so that we can share it with others you permit, in order to better serve you. For this purpose, we collect, store, and publish data, and process it automatically or in some cases by hand (manually).	Your Operation and Data you provide</p>
                <p>• the data about your age, gender, address, and email address which may be specially protected under the laws of your jurisdiction and be provided by you when you register a Webfm account.</p>
                <p>Transfer, store, or process your data across borders, including with the United States and other countries. Because Webfm operates on a global scale, with our users, partners, suppliers and employees all over the world, it is necessary for us to communicate data for the purpose of maintaining, analyzing and improving Webfm.	Your Operation and Data you provide</p>
                <p>• the content you create, for example novel or comments;</p>
                <p>• the metadata about the content itself, for example upload address or creation documents date, in accordance with the relevant legislation;</p>
                <p>• the type of content that you read or comment;</p>
               <p>• the application and function you use, and your operation during this;</p>
               <p>• the purchase or other transactions you do including the credit card data;</p>
               <p>• the time, frequency, and duration of your operation.</p>
               <p className="fw400">Application, Browser and Device Data</p>
               <p>• the device and software that you currently use, and other device characteristics;</p>
               <p>• the data that your device links to the Internet, including your IP address;</p>
               <p>• the data that is collected by Cookie (specified on Section 2) and other similar techniques.</p>
               <p className="fw400">Data that comes from Business Partner, Supplier and Third Party</p>
               <p>Share your contact details, personal data, or other data with a third party. The type of data you want to share will be different according to what you require us to share. For example:</p>
                <p className="bold">4. Data Sharing</p>
               <p>We will never sell any of your data to anyone, and we require partners and third parties to follow the rules that define how they are allowed and prohibited from using and disclosing the data we provide.</p>
                <p>Specifically, we share data with the following third parties:</p>
               <p>(a)We work with third-party partners who help us improve Webfm, which makes it possible to operate our companies and provide services to people around the world.</p>
                <p>(b)We do not provide any personally identifiable data</p>
               <p>(c)to these third-party ad servers or ad networks without your consent or except as part of a specific program or feature for which you will have the ability to opt-in or opt-out.</p>
                <p>(d)Members, subsidiaries, or affiliates of our corporate group, make Webfm improving and optimizing.</p>
               <p>(e)Law enforcement agencies, public or tax authorities or other organizations if legally required to do so.</p>
               <p className="bold">5. Data Security</p>
               <p>We take reasonable and appropriate technical and organizational measures to help protect personal data from unauthorized access, use, disclosure, alteration, and destruction. Unfortunately, the transmission of data via the internet is not completely secure and we cannot guarantee the security of your data transmitted via the Platform; any transmission is at your own risk. To help us protect personal data, we request that you use a strong password and never share your password with anyone or use the same password with other sites or accounts.</p>
                <p className="bold">6. Data Management or Deleting and Your Rights Exercising</p>
               <p>We provide the following tools for viewing, managing, downloading, and deleting your own personal data. You can also visit the Settings page of the product you are using to manage personal data. In addition, you may have other privacy rights under applicable law.</p>
                <p>You can use Personal Center, or open the client, click "My" - "Contact Customer Service", and then contact the customer service by email webfm.cs@hotmail.com to exercise your rights.</p>
                <p className="fw400">I.View and manage your personal data</p>
               <p>You are entitled to access, view and manage your personal data via Personal Center, or open the client, click "My" - "Contact Customer Service", and then contact the customer service by email webfm.cs@hotmail.com.</p>
               <p className="fw400">II.Delete your personal data or your account</p>
               <p>If you want to remove your personal data, you can:</p>
               <p>• Search for and remove certain personal data <br/>
                   We have provided you with a series of tools that will help you delete certain personal data. For example, you can use the Delete button to remove books from the shelf. You can remove the comments by clicking the Delete button. Once the comments are removed, other users will not be able to see them anymore.</p>
                <p>• Remove your account permanently <br/>You can contact us by e-mail at webfm.cs@hotmail.com to ask for your account to be removed.</p>
               <p>Once your account is removed, we will no longer supply you with any products or services. We will remove your personal data at your request, except as otherwise required by the law.</p>
                <p>We will help you to remove your account data and delete your payment records for unlocking chapters. If you permanently remove your account, you cannot reactivate it, and you cannot retrieve your reading, spending, or unlocking records, including the balance of your top-up voucher.</p>
                <p className="fw400">III.How long does it take to delete your personal data?</p>
               <p>The time required for deletion varies; for example, deleting your personal data is instantaneous and will be removed immediately. Deleting the account must not last more than 15 days from the date of receipt of the request. If you do not like it, please contact the customer service department and complain.</p>
                <p>You can use Personal Center to view your personal data or click "My" - "Contact Customer Service". You can inquire, correct, delete, cancel user account, and revoke authorized user's operation by contacting client. For safety reasons, you might want to submit a written request. Or some other way to prove your identity. You may be required to confirm your identity before you proceed with your application.</p>
                <p>A reply will be sent to you within 15 working days. If you don't like it, feel free to contact customer service to complain.</p>
                <p className="bold">7. Age Restrictions</p>
               <p>Webfm is not developed for children. This Platform is not directed at children under the age of 12 or equivalent minimum age in the relevant jurisdiction. If you believe that we have personal data about or collected from a child under the relevant age, please contact us at webfm.cs@hotmail.com.</p>
                <p>Users between 12 and 18 (each a “Teen”) may not access or use the Service unless (i) both the Teen and their parent or legal guardian have first agreed to these Terms of Service; and (ii) the Teen uses an account established by their parent or legal guardian, under such parent or guardian’s supervision, and with such parent or guardian’s permission. If you permit a Teen to use the Services, you hereby agree to these Terms of Service on behalf of both yourself and the Teen. You further agree that you are solely responsible for any and all use of the Service by your Teen regardless of whether such use was authorized by you.</p>
               <p className="bold">8. Data Retention Time</p>
               <p>We retain your data for as long as it is necessary to provide you with the service or fulfill the transactions you have requested or authorized. Where we do not need your data in order to provide the service to you, we retain it only for so long as we have a legitimate business purpose in keeping such data, including to comply with our legal obligations, enforce our agreements, resolve disputes, and as necessary for the establishment, exercise or defense of legal claims.</p>
                <p>You can delete your Account, and you should understand that upon deletion of your Account, you will lose the right to access or use all or part of the Platform. After you have deleted your account, we may retain certain data in an aggregated and anonymized format.</p>
<p className="bold">9. Changes of this policy</p>
               <p>We'll notify you before we make changes to this policy and give you the opportunity to review the revised policy before you choose to continue using our Products.</p>
<p className="bold">10. Complaints</p>
               <p>In the event that you wish to make a complaint about how we process your personal data, please submit your complaint via email at webfm.cs@hotmail.com. We will endeavor to deal with your request as soon as possible. This is without prejudice to your right to launch a claim with your data protection authority or follow the dispute resolution process provided in the Terms of Service.</p>
<p className="bold">11. Contact</p>
               <p>If you have questions about this policy, you can contact us as described below.
                   You can contact us by email or by mail at: <br/>webfm.cs@hotmail.com</p>

           </div>
            }


        </div>
    )
}
export default Page


